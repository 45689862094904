<template>
  <form class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md" v-on:submit.prevent="login">
    <h1 class="lg:text-2xl text-xl font-semibold mb-6" v-if="showTitle !== false">Đăng nhập</h1>
    <span class="mr-1" v-if="showRegister !== false">Bạn chưa có tài khoản?
        <router-link :to="{name: 'Register'}" class="text-blue-800">Đăng ký</router-link>
    </span>
    <div :class="{'bg-green-500': status === 'success', 'bg-red-500': status === 'error'}"
          class="border p-4 relative rounded-md uk-alert" v-if="message">
      <p class="text-white">{{ message }}</p>
    </div>
    <div v-if="code === 'user_not_active'">
      <button type="button" class="bg-green-600 font-semibold p-2 rounded-md text-center text-white w-full"
              v-on:click="sendActivationCode">Kích hoạt ngay
      </button>
    </div>
    <div>
      <label class="mb-0" for="identity">Tên đăng nhập</label>
      <input v-model="identity" id="identity" type="text" placeholder="Username"
              class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
    </div>
    <div>
      <label class="mb-0" for="password">Mật khẩu</label>
      <input v-model="password" id="password" type="password" placeholder=""
              class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
    </div>
    <div class="d-lg-flex justify-content-between align-items-center mb-4" v-if="showForgot !== false">
      <div>
        <router-link :to="{name: 'ForgotPassword'}" class="text-blue-800 ms-1">
          Quên mật khẩu?
        </router-link>
      </div>
    </div>
    <div>
      <button type="submit" class="bg-green-600 font-semibold p-2 mt-5 rounded-md text-center text-white w-full">
        Đăng nhập
      </button>
    </div>
    <div class="w-full flex justify-content-center">
      <GoogleLogin :callback="callback"/>
    </div>
      </form>
</template>

<style>
input, input:-webkit-autofill, .bootstrap-select.btn-group button{
  background-color: #f3f4f6  !important;
  height: 44px  !important;
  box-shadow: none  !important;
}
</style>

<script>
import ApiService from "@/core/services/api.service";
import {saveToken} from "@/core/services/jwt.service";

export default {
  name: 'LoginAuthenticate',
  data() {
    return {
      identity: "",
      password: "",
      message: "",
      code: "",
      status: "error"
    }
  },
  props: {
    messageProp: String,
    statusProp: String,
    next: String,
    showTitle: Boolean,
    showForgot: Boolean,
    showRegister: Boolean
  },
  methods: {
    callback(response) {
      window.postMessage({action: "onSignIn", value: {type: "google", token: response.credential}}, window.location.origin);
    },
    login() {
      this.message = "";

      let query = `mutation($identity: String!, $password: String!) {
        login(identity: $identity, password: $password) {
          access_token
          message
          code
        }
      }`;

      ApiService.graphql(query, {identity: this.identity, password: this.password})
          .then(({data}) => {
            if (data.data && data.data.login) {
              if (data.data.login.access_token) {
                saveToken(data.data.login.access_token);
                if (this.next) {
                  window.location = this.next;
                } else if (this.$route.query.next) {
                  window.location = this.$route.query.next;
                } else {
                  location.reload(true);
                }
              } else {
                this.message = data.data.login.message;
                this.code = data.data.login.code;
                this.status = "error";
              }
            } else {
              this.message = data.data.errors[0].message;
              this.status = "error";
            }
          })
          .catch((response) => {
            this.message = response.message;
            this.status = "error";
          });
    },
    sendActivationCode() {
      this.status = "";

      let query = `mutation($identity: String!) {
        sendActivationCode(identity: $identity)
      }`;

      ApiService.graphql(query, {identity: this.identity})
          .then(({data}) => {
            if (data.data && data.data.sendActivationCode) {
              this.message = "Link kích hoạt đã được gửi tới email của bạn. Vui lòng kiểm tra hòm thư của bạn";
              this.status = "success";
              this.code = "";
            } else {
              this.message = data.data.errors[0].message;
              this.status = "error";
            }
          })
          .catch((response) => {
            this.message = response.message;
            this.status = "error";
          });
    }
  },
  watch: {
    messageProp: {
      handler(newVal) {
        this.message = newVal;
      }
    },
    statusProp: {
      handler(newVal) {
        this.status = newVal;
      }
    }
  }
}
</script>
