<template>
  <div>
    <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
      <login-authenticate :message-prop="message" :status-prop="status" next="/" :show-title="true" :show-register="true" :show-forgot="true"/>
    </div>
  </div>
</template>

<script>
import { getUser } from "../core/services/jwt.service";
import {changePageTitle} from "../core/services/utils.service";
import LoginAuthenticate from "./authenticate/Login.vue";

export default {
  name: 'Login',
  components: {LoginAuthenticate},
  data() {
    return {
      message: "",
      status: ""
    }
  },
  mounted() {
    if (this.$route.query.ref && this.$route.query.ref === "register") {
      this.message = "Bạn đã đăng ký thành công. Vui lòng kích hoạt tài khoản trước khi đăng nhập (check email)";
      this.status = "success";
    } else if (this.$route.query.ref && this.$route.query.ref === "validate") {
      this.message = "Xin chúc mừng, bạn đã xác thực thành công. Vui lòng đăng nhập";
      this.status = "success";
    } else if (this.$route.query.ref && this.$route.query.ref === "changePassword") {
      this.message = "Xin chúc mừng, bạn đã đổi mật khẩu thành công. Vui lòng đăng nhập lại";
      this.status = "success";
    }

    let user = getUser();
    if (user) {
      window.location = "/";
    }

    changePageTitle("Đăng nhập")
  }
}
</script>
